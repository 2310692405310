<template>
	<div class="about">
		<div class="header">
			<img src="@/assets/images/about_header.png" alt="">
			<div class="inner animated fadeIn">
				<p>{{$t('lang.home.nav1')}}</p>
				<p>{{$t('lang.about.header1')}}</p>
			</div>
		</div>
		<div class="main">
			<img src="@/assets/images/about_main.png" alt="" v-if="$t(isShow)=='en'">
			<img src="@/assets/images/about_main_cn.png" alt="" v-if="$t(isShow)=='cn'">
		</div>
	</div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
		isShow:"lang.about.isShow"
	};
  }
};
</script>

<style lang="scss" scoped>
.about {
  .main {
    width: 1250px;
    margin: 120px auto;
    img {
      width: 65vw;
    }
  }
}
</style>

